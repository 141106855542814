export const levels = [
    {
        id: 1,
        name: "Beginner",
        points: 50,
        perQuestion: 5
    },
    {
        id: 2,
        name: "Intermediate",
        points: 80,
        perQuestion: 8
    },
    {
        id: 3,
        name: "Advance",
        points: 100,
        perQuestion: 10
    }
]

export function findLevel(id) {
    return levels.find(level => level.id === id)
}