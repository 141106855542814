import React, { useEffect, useState } from 'react'
import './css/screen3.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { levels as defaultLevels } from '../utils/levels';
import { useTranslation } from 'react-i18next';
import { getQuiz } from 'services/home';

const Screen3 = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [levels, setLevels] = useState(defaultLevels);
    const [quiz, setQuiz] = useState(location?.state);
    const [quizLevel, setQuizLevel] = useState(levels[0]);
    const handleQuizClick = (quizValue, quizLevelValue) => {
        setQuiz(quizValue);
        setQuizLevel(quizLevelValue);
    };
    const { t } = useTranslation();

    useEffect(() => {
        if (location.state?.id) {
            const levelPromises = defaultLevels.map((level) => getQuiz(location.state?.id, level.id, localStorage.getItem('selectedLanguage') || "1"));
            Promise.all(levelPromises).then(res => {
                setLevels(res.map((quiz, index) => ({ ...defaultLevels[index], questions: quiz.length, points: quiz.length * defaultLevels[index].perQuestion })));
            });
        }
    }, [location.state?.id]);

    useEffect(() => {
        if (levels) {
            handleQuizClick(location.state, levels[0]);
        }
    }, [levels, location.state])

    return (
        <div className='home level-page'>
            <div className="main">
                <div className="container-fluid box-wrapper difficult">
                    <div className="page-header pb-5">
                        <div className="box-wrapper mx-auto text-center position-relative header-container">
                            <button type="button" className="icon-button" onClick={() => navigate(-1)}>
                                <img src="/image/back.png" className='arrow' alt="back" />
                            </button>
                            <span className="levels-title">{location?.state?.name}</span>
                        </div>
                    </div>
                    <div className="quiz-thumbnail">
                        <img src={location?.state?.image} alt="Quiz" />

                    </div>
                    <div className="home-profile">
                        <div className="row gx-1">
                            <div className="col-12">
                                <h3 className="section-title2 mb-3">{location?.state?.name} {t('15')}</h3>
                            </div>
                            <div className="col-12 quiz-info-meta-level">
                                <span className="quiz-info-meta-circle-img"><img src="/image/Group 19.png" alt="Quiz" /></span>
                                <span className='title-gap'>{quizLevel.questions} {t('16')}</span>
                            </div>


                        </div>
                        <div className="quiz-info-content">
                            <h4 className="quiz-info-content-title">{t('18')}</h4>
                            <p>{location?.state?.description}</p>
                        </div>
                        <h3 className="choose-title mb-3">{t('21')}</h3>
                        <div className='pb-2'>

                            <div className={`row gx-0 beginner-tab d-flex align-items-baseline ${quizLevel.id == '1' ? 'active' : ''}`} onClick={() => handleQuizClick(location?.state, levels[0])}>
                                <div className="col-7 text-start">
                                    <div className="d-flex align-items-baseline">
                                        <div className="avatar2">
                                            <img src="/image/Beginner.png" alt="level" />
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <div className="home-profile-name">{t('22')}</div>
                                            {/* <div className="home-profile-status">Guest</div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 text-end">
                                    <div className="h-100 d-flex align-items-center justify-content-end">
                                        <div className="home-profile-points">
                                            <span className='points-text'> {levels[0].points} {t('17')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='pb-2'>

                            <div className={`row gx-0 intermediate-tab d-flex align-items-baseline ${quizLevel.id == '2' ? 'active' : ''}`} onClick={() => handleQuizClick(location?.state, levels[1])}>
                                <div className="col-7 text-start">
                                    <div className="d-flex align-items-baseline">
                                        <div className="avatar2">
                                            <img src="/image/Intermediate.png" alt="level" />
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <div className="home-profile-name">{t('23')}</div>
                                            {/* <div className="home-profile-status">Guest</div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 text-end">
                                    <div className="h-100 d-flex align-items-center justify-content-end">
                                        <div className="home-profile-points">
                                            <span className='points-text'> {levels[1].points} {t('17')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div>

                            <div className={`row gx-0 advance-tab d-flex align-items-baseline ${quizLevel.id == '3' ? 'active' : ''}`} onClick={() => handleQuizClick(location?.state, levels[2])}>
                                <div className="col-7 text-start">
                                    <div className="d-flex align-items-baseline">
                                        <div className="avatar2">
                                            <img src="/image/Advance.png" alt="level" />
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <div className="home-profile-name">{t('24')}</div>
                                            {/* <div className="home-profile-status">Guest</div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 text-end">
                                    <div className="h-100 d-flex align-items-center justify-content-end">
                                        <div className="home-profile-points">
                                            <span className='points-text'> {levels[2].points} {t('17')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <NavLink
                                to="/finding"
                                className={["btn btn-lg button-primary w-100", quizLevel.questions ? "" : "disabled"].join(" ")}
                                state={{ qid: location?.state, level: quizLevel }}
                            >
                                {t('19')}
                            </NavLink>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Screen3