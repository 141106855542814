import React, { useEffect, useState } from 'react'
import '../css/footer.css'
import { NavLink, useLocation } from 'react-router-dom'
const Footer = () => {
    const [activeLink, setActiveLink] = useState('home'); // Initial active link
    const location = useLocation();
    useEffect(() => {
        // Update activeLink when the location changes
        //   setActiveLink(location.pathname.replace('/', ''));
        const currentPath = location.pathname.replace('/', '');
        setActiveLink(currentPath === '' ? 'home' : currentPath);
    }, [location]);
    const handleLinkClick = (link) => {
        localStorage.setItem("menu", link)
        setActiveLink(link);
        // You can add additional logic here if needed
    };
    const activeClass = (route) => {
        const currentPath = location.pathname.replace('/', '');

        if (currentPath === '' && (route === 'home' || route === '')) {
            return 'col active';
        }

        return currentPath === route ? 'col active' : 'col';
    };
    return (
        <>
            <div className="bottom-spacer" style={{ height: "65px" }}></div>
            <div className="bottom-nav">
                <div className="bottom-nav-bg row gx-0 box-wrapper mx-auto container-fluid d-flex align-content-center">
                    <div className={activeClass('home')}>
                        <div className="nav-box mt-2">
                            <NavLink to="/home" exact="true" className="nav-box-ico" onClick={() => handleLinkClick('home')}>
                                {
                                    activeLink === 'home' ?
                                        <svg width="98" height="50" viewBox="0 0 98 50" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_758_14)"> <path d="M59 35.0001C59 35.6905 58.4404 36.2501 57.75 36.2501H40.25C39.5596 36.2501 39 35.6905 39 35.0001V23.7501H35.25L48.1591 12.0145C48.6359 11.5811 49.3641 11.5811 49.8409 12.0145L62.75 23.7501H59V35.0001Z" fill="white" /> </g> <defs> <clipPath id="clip0_758_14"> <rect width="30" height="30" fill="white" transform="translate(34 10)" /> </clipPath> </defs> </svg>
                                        :
                                        <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M58.5 36.2501H41C40.3096 36.2501 39.75 35.6905 39.75 35.0001V23.7501H36L48.9091 12.0145C49.3859 11.5811 50.1141 11.5811 50.5909 12.0145L63.5 23.7501H59.75V35.0001C59.75 35.6905 59.1904 36.2501 58.5 36.2501ZM42.25 33.7501H57.25V21.447L49.75 14.6288L42.25 21.447V33.7501Z" fill="#969696" />
                                        </svg>

                                }
                            </NavLink>
                        </div>
                    </div>
                    <div className={activeClass('more-games')}>
                        <div className="nav-box mt-2">
                            <NavLink to="/more-games" exact="true" className="nav-box-ico" onClick={() => handleLinkClick('more-games')}>
                                {
                                    activeLink === 'more-games' ?
                                        <svg width="98" height="50" viewBox="0 0 98 50" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clip-path="url(#clip0_758_1421)"> <path d="M37.75 26.25H47.75V13.75H37.75V26.25ZM37.75 36.25H47.75V28.75H37.75V36.25ZM50.25 36.25H60.25V23.75H50.25V36.25ZM50.25 13.75V21.25H60.25V13.75H50.25Z" fill="white" /> </g> <defs> <clipPath id="clip0_758_1421"> <rect width="30" height="30" fill="white" transform="translate(34 10)" /> </clipPath> </defs> </svg>
                                        :
                                        <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M51 36.25V23.75H61V36.25H51ZM38.5 26.25V13.75H48.5V26.25H38.5ZM46 23.75V16.25H41V23.75H46ZM38.5 36.25V28.75H48.5V36.25H38.5ZM41 33.75H46V31.25H41V33.75ZM53.5 33.75H58.5V26.25H53.5V33.75ZM51 13.75H61V21.25H51V13.75ZM53.5 16.25V18.75H58.5V16.25H53.5Z" fill="#969696" />
                                        </svg>

                                }
                            </NavLink>
                        </div>
                    </div>

                    <div className={activeClass('championship')}>
                        <div className="nav-box mt-3">
                            <NavLink to="/championship" className="nav-box-ico" onClick={() => handleLinkClick('championship')}>
                                {
                                    activeLink === 'championship' ?
                                        <svg width="90" height="35" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.50722 6.5005L8.75632 9.9999L13.9891 2.67394C14.3904 2.11218 15.1711 1.98206 15.7329 2.38331C15.8451 2.46349 15.9433 2.56169 16.0235 2.67394L21.2564 9.9999L26.5054 6.5005C27.0799 6.11756 27.8559 6.27278 28.2389 6.84719C28.4035 7.09421 28.4749 7.39178 28.4403 7.68661L26.3863 25.1459C26.3121 25.7754 25.7786 26.2499 25.1448 26.2499H4.86788C4.23403 26.2499 3.7005 25.7754 3.62644 25.1459L1.5724 7.68661C1.49174 7.00099 1.98217 6.37979 2.66779 6.29913C2.96264 6.26444 3.2602 6.33583 3.50722 6.5005ZM15.0064 18.7499C16.387 18.7499 17.5064 17.6307 17.5064 16.2499C17.5064 14.8692 16.387 13.7499 15.0064 13.7499C13.6256 13.7499 12.5064 14.8692 12.5064 16.2499C12.5064 17.6307 13.6256 18.7499 15.0064 18.7499Z" fill="#969696" />
                                        </svg>
                                        :
                                        <svg width="99" height="35" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.37137 10.0812L5.97944 23.7499H24.0333L25.6413 10.0812L20.629 13.4228L15.0064 5.55106L9.38368 13.4228L4.37137 10.0812ZM3.50722 6.5005L8.75632 9.9999L13.9891 2.67394C14.3904 2.11217 15.1711 1.98206 15.7329 2.38332C15.8451 2.4635 15.9433 2.5617 16.0235 2.67394L21.2564 9.9999L26.5054 6.5005C27.0799 6.11756 27.8559 6.27277 28.2389 6.84719C28.4035 7.09421 28.4749 7.39177 28.4403 7.68661L26.3863 25.146C26.3121 25.7755 25.7786 26.2499 25.1448 26.2499H4.86788C4.23403 26.2499 3.7005 25.7755 3.62644 25.146L1.5724 7.68661C1.49174 7.00099 1.98217 6.37979 2.66779 6.29912C2.96264 6.26444 3.2602 6.33582 3.50722 6.5005ZM15.0064 18.7499C13.6256 18.7499 12.5064 17.6306 12.5064 16.2499C12.5064 14.8691 13.6256 13.7499 15.0064 13.7499C16.387 13.7499 17.5064 14.8691 17.5064 16.2499C17.5064 17.6306 16.387 18.7499 15.0064 18.7499Z" fill="#969696" />
                                        </svg>

                                }
                            </NavLink>

                        </div>
                    </div>
                    <div className={activeClass('leaderboard')}>
                        <div className="nav-box mt-2">
                            <NavLink to="/leaderboard" exact="true" className="nav-box-ico" onClick={() => handleLinkClick('leaderboard')}>
                                {
                                    activeLink === 'leaderboard' ?
                                        <svg width="98" height="50" viewBox="0 0 98 50" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_758_1406)"> <path d="M49.5177 16.25H60.25C60.9404 16.25 61.5 16.8096 61.5 17.5V35C61.5 35.6904 60.9404 36.25 60.25 36.25H37.75C37.0597 36.25 36.5 35.6904 36.5 35V15C36.5 14.3097 37.0597 13.75 37.75 13.75H47.0177L49.5177 16.25ZM47.75 21.25V31.25H50.25V21.25H47.75ZM52.75 25V31.25H55.25V25H52.75ZM42.75 27.5V31.25H45.25V27.5H42.75Z" fill="white" /> </g> <defs> <clipPath id="clip0_758_1406"> <rect width="30" height="30" fill="white" transform="translate(34 10)" /> </clipPath> </defs> </svg>
                                        :
                                        <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M50.2677 16.25H61C61.6904 16.25 62.25 16.8096 62.25 17.5V35C62.25 35.6904 61.6904 36.25 61 36.25H38.5C37.8097 36.25 37.25 35.6904 37.25 35V15C37.25 14.3097 37.8097 13.75 38.5 13.75H47.7677L50.2677 16.25ZM39.75 16.25V33.75H59.75V18.75H49.2323L46.7322 16.25H39.75ZM48.5 21.25H51V31.25H48.5V21.25ZM53.5 25H56V31.25H53.5V25ZM43.5 27.5H46V31.25H43.5V27.5Z" fill="#969696" />
                                        </svg>

                                }
                            </NavLink>
                        </div>
                    </div>
                    <div className={activeClass('profile')}>
                        <div className="nav-box mt-2">
                            <NavLink to="/profile" className="nav-box-ico" onClick={() => handleLinkClick('profile')}>
                                {
                                    activeLink === 'profile' ?
                                        <svg width="98" height="50" viewBox="0 0 98 50" fill="none" xmlns="http://www.w3.org/2000/svg"> <g clipPath="url(#clip0_758_1503)"> <path d="M59 37.5H39V35C39 31.5483 41.7982 28.75 45.25 28.75H52.75C56.2017 28.75 59 31.5483 59 35V37.5ZM49 26.25C44.8579 26.25 41.5 22.8921 41.5 18.75C41.5 14.6079 44.8579 11.25 49 11.25C53.1421 11.25 56.5 14.6079 56.5 18.75C56.5 22.8921 53.1421 26.25 49 26.25Z" fill="white" /> </g> <defs> <clipPath id="clip0_758_1503"> <rect width="30" height="30" fill="white" transform="translate(34 10)" /> </clipPath> </defs> </svg>
                                        :
                                        <svg width="99" height="50" viewBox="0 0 99 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M59.75 37.5H57.25V35C57.25 32.9289 55.5711 31.25 53.5 31.25H46C43.9289 31.25 42.25 32.9289 42.25 35V37.5H39.75V35C39.75 31.5483 42.5482 28.75 46 28.75H53.5C56.9517 28.75 59.75 31.5483 59.75 35V37.5ZM49.75 26.25C45.6079 26.25 42.25 22.8921 42.25 18.75C42.25 14.6079 45.6079 11.25 49.75 11.25C53.8921 11.25 57.25 14.6079 57.25 18.75C57.25 22.8921 53.8921 26.25 49.75 26.25ZM49.75 23.75C52.5114 23.75 54.75 21.5114 54.75 18.75C54.75 15.9886 52.5114 13.75 49.75 13.75C46.9886 13.75 44.75 15.9886 44.75 18.75C44.75 21.5114 46.9886 23.75 49.75 23.75Z" fill="#969696" />
                                        </svg>

                                }
                            </NavLink>

                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Footer