import React, { useState, useEffect } from 'react'
import './css/screen3.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { languages } from 'constants/constants';

const Language = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || "1");

  useEffect(() => {
    if (language) {
      console.log("Value of language *******####### ", language);
      localStorage.setItem('selectedLanguage', language);
      i18n.changeLanguage(language);
    }
  }, [language, i18n])
  return (
    <div className='home'>
      <div className="main">
        <div className="container-fluid box-wrapper difficult">
          <div className="page-header pb-5">
            <div className="box-wrapper mx-auto text-center position-relative header-container">
              <button type="button" className="icon-button" onClick={() => navigate(-1)}>
                <img src="/image/back.png" className='arrow' alt="back" />
              </button>
              <span className="levels-title">{t('11')}</span>
            </div>
          </div>
          <div className="home-profile">
            <h3 className="choose-title mb-3">{t('43')}:</h3>

            <form className="languageForm">
              {languages.map(lang => (
                <div key={lang.id} className="choose-option language-between" onClick={() => setLanguage(lang.id)}>
                  <label htmlFor={lang.id}>{lang.name}</label>
                  <input id={lang.id} type="radio" name="r" value={lang.id} checked={language === lang.id} />
                </div>
              ))}
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Language