import React, { useState, useEffect } from 'react'
import './css/screen3.css';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const GameDetails = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className='home'>
            <div className="main">
                <div className="container-fluid box-wrapper difficult">

                    <div className="page-header pb-4">
                        <div className="box-wrapper mx-auto text-center position-relative header-container">
                            <button type="button" className="icon-button" onClick={() => navigate(-1)}>
                                <img src="/image/back.png" className='arrow' alt="back" />
                            </button>
                            <span className="game-title">{location?.state?.name}</span>
                        </div>
                    </div>
                    <div className="quiz-thumbnail">
                        <img src={location?.state?.image} alt="Quiz" />

                    </div>
                    <div className="quiz-info">

                        <div className="">
                            <div className="row quiz-info-row gx-1">

                                <div className="col-6">
                                    <h3 className="section-title2 mb-3">{location?.state?.name} {t('15')}</h3>
                                </div>
                                <div className="col-6 quiz-info-meta2">
                                    <span className="quiz-info-meta-circle-img"><img src="/image/Group 19.png" alt="Quiz" /></span>
                                    <span className='title-gap'>10 {t('16')}</span>
                                </div>
                                {/* <div className="col-6 quiz-info-meta2">
                                    <span className="quiz-info-meta-circle-img"><img src="/image/Group 3.png" alt="Quiz" /></span>
                                    <span className='title-gap'>100 Points</span>
                                </div> */}
                            </div>
                        </div>
                        <div className="quiz-info-content">
                            <h4 className="quiz-info-content-title">{t('18')}</h4>
                            <p>{location?.state?.description}</p>
                        </div>

                        <NavLink to='/levels' className="btn btn-lg button-primary w-100" state={location?.state}>{t('19')}</NavLink>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default GameDetails