export const DEFAULT_PROFILE_IMG = "/assets/images/avatar.png";
export const languages = [
    { id: "0", name: "English" },
    { id: "1", name: "French" },
    { id: "2", name: "Arabic" },
    { id: "3", name: "Portuguese" },
    { id: "4", name: "Swahili" },
    { id: "5", name: "Amharic" },
    { id: "6", name: "Spanish" },
    { id: "7", name: "Shona" },
    { id: "8", name: "Tok Pisin" },
    { id: "9", name: "Kikongo" },
    { id: "10", name: "Bahasa (Indonesian)" },
    { id: "11", name: "Khmer" },
    { id: "12", name: "Russian" },
    { id: "13", name: "Oromo" },
];
