import { DEFAULT_PROFILE_IMG } from "constants/constants";

const frequencyMap = new Map([
    ["daily", "day"],
    ["weekly", "week"],
    ["one_day", "day"],
    ["one_week", "week"]
]);

const packColorMap = new Map([
    ["1", "254, 158, 68"],
    ["2", "71, 196, 117"],
    ["3", "40, 113, 251"],
    ["4", "227 0 0"]
]);

const offerColorMap = new Map([
    ["Subscription", "254, 158, 68"],
    ["One Time Purchase", "25, 135, 84"]
]);

export function getProfileImage(imgSrc) {
    return imgSrc || DEFAULT_PROFILE_IMG;
}

export {
    frequencyMap,
    packColorMap,
    offerColorMap
}