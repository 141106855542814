import React, { useEffect, useState } from 'react'
import 'components/css/login.css';
import { NavLink } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import { useNavigate } from 'react-router-dom';
import { sendOtp, login } from '../../services/auth-api';
import { useTranslation } from 'react-i18next';
import { setSessionData } from 'services/session.service';
import { routes } from 'constants/routes';
import i18n from 'utils/i18n';
import { useSession } from 'providers/SessionProvider';

const Login = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { getUser } = useSession();
    const [otp, setOtp] = useState('');
    const [ButtonDisabled, setButtonDisabled] = useState(false);
    const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || "1");
    const [phone, setPhone] = useState({});
    const [message, setMessage] = useState({
        message: "",
        error: false
    })

    const validatePhoneNumber = (phoneNumber) => {
        const phoneRegex = /^\d{8}$/;

        return phoneRegex.test(phoneNumber);
    }

    const sendOtpUser = async () => {
        if (validatePhoneNumber(phone)) {
            await sendOtp({ mobileNumber: phone }).then((res) => {
                console.log(res)
                setMessage({
                    message: res.message,
                    error: false
                });
            }).catch((err) => {
                console.log(err)
            })
        } else {
            setMessage({
                message: "Phone Number is not valid",
                error: true
            })
        }
    }

    const handleNumInputsChange = (numInputs) => {
        setOtp(numInputs);
        setButtonDisabled(numInputs.length !== 4 || isNaN(numInputs));
    };

    const handleButtonClick = async () => {
        await login({ mobileNumber: parseInt(phone), otp: parseInt(otp) }).then((res) => {
            setSessionData("token", res.data.token);
            getUser();
            navigate(routes.home);
        }).catch((err) => {
            console.log(err)
            setMessage({
                message: err.error,
                error: true
            })
        })
        // navigate('/profile');
    };

    useEffect(() => {
        if (language) {
            localStorage.setItem('selectedLanguage', language);
            i18n.changeLanguage(language);
        }
    }, [language])

    return (
        <div className='home'>
            <div className="main-div">
                <div className="container-fluid box-wrapper login">
                    <div className="row pt-5">
                        <div className="col-4"></div>
                        <div className="col-4 login-thumbnail">
                            <img src="/image/login.png" alt="login" />
                        </div>
                        <div className="col-4 text-end">
                            <div className="pt-2">
                                <select
                                    className="choose-option"
                                    id="dropdown"
                                    value={language}
                                    onChange={(event) => setLanguage(event.target.value)}
                                >
                                    <option className="choose-option" value="1">French</option>
                                    <option value="0">English</option>
                                    <option value="2">Arabic</option>
                                    <option value="3">Portuguese</option>
                                    <option value="4">Swahili</option>
                                    <option value="5">Amharic</option>
                                    <option value="6">Spanish</option>
                                    <option value="7">Shona</option>
                                    <option value="8">Tok Pisin</option>
                                    <option value="9">Kikongo</option>
                                    <option value="10">Bahasa (Indonesian)</option>
                                    <option value="11">Khmer</option>
                                    <option value="12">Russian</option>
                                    <option value="13">Oromo</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="login-header pb-4">
                        <div className="box-wrapper mx-auto text-center position-relative">
                            <span className="modal-level-title game-title">{t('50')}</span>
                        </div>
                    </div>
                    <div className="login-info">
                        <div className="login-thumbnail2">
                            <img src="/image/login_img.png" alt="Quiz" />
                        </div>
                        <h3 className="login-title mb-3 mt-3 text-center">{t('51')}</h3>
                        {
                            message.message && <div class="mb-3">
                                <span className={`alert alert-${message.error ? 'danger' : 'success'}`}>{message.message}</span>
                            </div>
                        }

                        <div className="phone-info">
                            <div className="phone-number-info">
                                <input
                                    className="form-control form-mobile-no"
                                    placeholder={t('81')}
                                    type="tel"
                                    inputMode="numeric"
                                    onChange={(event) => {
                                        setPhone(event.target.value);
                                    }}
                                />

                            </div>
                            <div className="send_otp">
                                <button className="icon-button send_otp_link" onClick={() => sendOtpUser()}>
                                    {t('52')}
                                </button>
                            </div>

                            <p className="Verify_otp">{t('53')}</p>

                            <div className="otp_section">

                                <OtpInput
                                    value={otp}
                                    numInputs={4}
                                    renderInput={(props, index) => (
                                        <input
                                            {...props}
                                            type="tel"  // Use type="tel" to display the numeric keypad on mobile devices
                                            inputMode="numeric"  // Specify the numeric input mode
                                        />
                                    )}
                                    inputStyle="form-control form-otp"
                                    onChange={handleNumInputsChange}
                                />
                            </div>

                            <button className="btn btn-primary btn-pm-custom" type="button" onClick={handleButtonClick} disabled={ButtonDisabled}>{t('54')}</button>

                            <div className="resend_otp">
                                {t('55')} <NavLink to='/#' className="send_otp_link" > {t('56')}</NavLink>
                            </div>

                            <div className='footer-login'>
                                {t('64')}
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    )
}

export default Login