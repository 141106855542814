import { routes } from "constants/routes";
import { Navigate, Outlet } from "react-router-dom";
import { getSessionData } from "services/session.service";


const AuthGuard = ({ isProtected = true }) => {
    return (
        isProtected
            ? Boolean(getSessionData("token"))
                ? <Outlet /> : <Navigate to={routes.login} />
            : !Boolean(getSessionData("token"))
                ? <Outlet /> : <Navigate to={routes.home} />
    );
}

export default AuthGuard;
