// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en.json';
import translationFR from '../locales/fr.json';
import translationAR from '../locales/ar.json';
import translationPG from '../locales/pg.json';
import translationSWC from '../locales/swc.json';
import translationAM from '../locales/am.json';
import translationES from '../locales/es.json';
import translationSN from '../locales/sn.json';
import translationTP from '../locales/tp.json';
import translationKG from '../locales/kg.json';
import translationID from '../locales/id.json';
import translationKM from '../locales/km.json';
import translationRU from '../locales/ru.json';
import translationOM from '../locales/om.json';
import { getFunFact } from '../services/home'

const data = await getFunFact().then((data) => {
  return data
})

let totalFacts = 0
data.data.forEach((element, i) => {
  totalFacts = totalFacts + 1
  translationEN = { ...translationEN, [`fun_fact_${i + 1}`]: element.english }
  translationFR = { ...translationFR, [`fun_fact_${i + 1}`]: element.french }
  translationAR = { ...translationAR, [`fun_fact_${i + 1}`]: element.arabic }
  translationPG = { ...translationPG, [`fun_fact_${i + 1}`]: element.portuguese }
  translationSWC = { ...translationSWC, [`fun_fact_${i + 1}`]: element.swahili }
  translationAM = { ...translationAM, [`fun_fact_${i + 1}`]: element.amharic }
  translationES = { ...translationES, [`fun_fact_${i + 1}`]: element.spanish }
  translationSN = { ...translationSN, [`fun_fact_${i + 1}`]: element.shona }
  translationTP = { ...translationTP, [`fun_fact_${i + 1}`]: element.tokPisin }
  translationKG = { ...translationKG, [`fun_fact_${i + 1}`]: element.kikongo }
  translationID = { ...translationID, [`fun_fact_${i + 1}`]: element.bahasa }
  translationKM = { ...translationKM, [`fun_fact_${i + 1}`]: element.khmer }
  translationRU = { ...translationRU, [`fun_fact_${i + 1}`]: element.russian }
  translationOM = { ...translationOM, [`fun_fact_${i + 1}`]: element.oromo }
});

localStorage.setItem("total_facts", totalFacts)

const resources = {
  0: {
    translation: translationEN,
  },
  1: {
    translation: translationFR,
  },
  2: {
    translation: translationAR,
  },
  3: {
    translation: translationPG,
  },
  4: {
    translation: translationSWC,
  },
  5: {
    translation: translationAM,
  },
  6: {
    translation: translationES,
  },
  7: {
    translation: translationSN,
  },
  8: {
    translation: translationTP,
  },
  9: {
    translation: translationKG,
  },
  10: {
    translation: translationID,
  },
  11: {
    translation: translationKM,
  },
  12: {
    translation: translationRU,
  },
  13: {
    translation: translationOM,
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: '1', // default language
    interpolation: {
      escapeValue: false, // react already escapes values
    },
  });

export default i18n;
