import { routes } from "constants/routes";
import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { authenticateWithISE2 } from "services/auth-api";
import Spinner from 'react-bootstrap/Spinner';


const Register = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const ise2 = searchParams.get("token");

        if (ise2) {
            authenticateWithISE2(encodeURIComponent(ise2)).then((res) => {
                console.log(res)
                if (res.data?.isSubscribed) {
                    navigate(routes.home);
                } else {
                    navigate(routes.login, { state: { ...res.data, ise2 } });
                }
            }).catch(ex => {
                console.log(ex);
                navigate(routes.login);
            });
        } else {
            navigate(routes.login);
        }
    }, [searchParams, navigate]);

    return (
        <div className="register">
            <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    )
}

export default Register;
